
import { defineComponent, ref, reactive, toRef, watch, toRefs, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import IconSVG from "@/components/IconSVG.vue";
import { useAuth } from '@/_modules/auth';
import { Roles } from '@/_utils/types';
export default defineComponent({
    components: {
        IconSVG
    },
    setup() {
        const { account, feature } = useAuth()
        const roles = reactive<Roles>({
			bigData: null,
			academy: null,
			eLearning: null
		})
        const env = reactive({
			bigData: false,
			academy: false,
			eLearning: false
		});
        const collapsed = ref<boolean>(false)
        const route = useRoute()
        const state = reactive({
            selectedKeys: [String(route.name).split("-")[0]]
        })
        watch(() => route.name,
            async newName => {
                state.selectedKeys = [String(newName).split("-")[0]]
            }
        )
        watchEffect(()=>{
            if(account.value){
                const currentAccount = account.value
                roles.bigData = currentAccount?.account_roles?.find(ele => ele.role_featured === 'BIGDATA')?.roles_permission ?? null
                roles.academy = currentAccount?.account_roles?.find(ele => ele.role_featured === 'ACADEMY')?.roles_permission ?? null
                roles.eLearning = currentAccount?.account_roles.find(ele => ele.role_featured === 'ELEARNING')?.roles_permission ?? null
            }
            if(feature.value) {
                const tmp = feature.value?.feature
                env.bigData = ["true", true].includes(tmp?.FEATURE_BIGDATA ?? false)
                env.academy = ["true", true].includes(tmp?.FEATURE_ACADEMY ?? false)
                env.eLearning = ["true", true].includes(tmp?.FEATURE_ELEARNING ?? false)
            }
        })
        const selectedKeys = toRef(state, 'selectedKeys')

        return {
            collapsed,
            selectedKeys,
            env,
            ...toRefs(roles)
        }
    },
})
